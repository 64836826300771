isMobile = false;
if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
    isMobile = true;
}

function checkMobile() {
    if (isMobile) {
        document.querySelector("body").classList.add("is--mobile");
        document.querySelector("body").classList.remove("is--desktop");
    } else {
        document.querySelector("body").classList.remove("is--mobile");
        document.querySelector("body").classList.add("is--desktop");
    }
}
checkMobile();

function isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
        rect.top >= 0 && rect.left >= 0 &&
        // rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

let swiperScript = 0;

window.addEventListener("scroll", function(e) {

    if (document.querySelectorAll(".header").length) {
        var navbar = document.querySelectorAll(".header")[0];

        function addFixedMenu() {
            if (window.pageYOffset >= navbar.clientHeight + 300) {
                navbar.classList.add("fixed-header-scroll");
                document.querySelector("body").classList.add("menu-is-fixed");
            } else {
                navbar.classList.remove("fixed-header-scroll");
                document.querySelector("body").classList.remove("menu-is-fixed");
            }
        }

        if (document.body.clientHeight > (window.innerHeight + 400)) {
            addFixedMenu();
        }
    }
});



let slideUp = (target, duration = 500) => {

    target.style.transitionProperty = 'height, margin, padding';
    target.style.transitionDuration = duration + 'ms';
    target.style.boxSizing = 'border-box';
    target.style.height = target.offsetHeight + 'px';
    target.offsetHeight;
    target.style.overflow = 'hidden';
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    window.setTimeout(() => {
        target.style.display = 'none';
        target.style.removeProperty('height');
        target.style.removeProperty('padding-top');
        target.style.removeProperty('padding-bottom');
        target.style.removeProperty('margin-top');
        target.style.removeProperty('margin-bottom');
        target.style.removeProperty('overflow');
        target.style.removeProperty('transition-duration');
        target.style.removeProperty('transition-property');
        //alert("!");
    }, duration);
}

/* SLIDE DOWN */
let slideDown = (target, duration = 500) => {

    target.style.removeProperty('display');
    let display = window.getComputedStyle(target).display;
    if (display === 'none') display = 'block';
    target.style.display = display;
    let height = target.offsetHeight;
    target.style.overflow = 'hidden';
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    target.offsetHeight;
    target.style.boxSizing = 'border-box';
    target.style.transitionProperty = "height, margin, padding";
    target.style.transitionDuration = duration + 'ms';
    target.style.height = height + 'px';
    target.style.removeProperty('padding-top');
    target.style.removeProperty('padding-bottom');
    target.style.removeProperty('margin-top');
    target.style.removeProperty('margin-bottom');
    window.setTimeout(() => {
        target.style.removeProperty('height');
        target.style.removeProperty('overflow');
        target.style.removeProperty('transition-duration');
        target.style.removeProperty('transition-property');
    }, duration);
}

/* TOOGLE */
var slideToggle = (target, duration = 500) => {
    if (window.getComputedStyle(target).display === 'none') {
        return slideDown(target, duration);
    } else {
        return slideUp(target, duration);
    }
}

const navItemToOpenA = document.querySelectorAll('.nav__level-1>.menu__item--has-children>a');
const navItemToOpenLi = document.querySelectorAll('.nav__level-1>.menu__item--has-children');

const navItemToOpenA2 = document.querySelectorAll('.nav__level-2>li>a');
const navItemToOpenLi2 = document.querySelectorAll('.nav__level-2>li');
let loginBtn = document.querySelector(".login__btn");
let loginWrapper = document.querySelector(".login__wrapper");
let loginList = document.querySelector(".login__list");

document.querySelector(".nav-toggle").addEventListener("click", function(e) {
    e.preventDefault();
    e.stopPropagation();

    this.classList.toggle("open");
    if (this.classList.contains("open")) {

        document.querySelector("body").classList.add("menu-open");
        slideDown(document.querySelector(".nav"), 300);

    } else {

        if (document.querySelector(".nav ul.open")) {
            slideUp(document.querySelector(".nav ul.open"), 300);
        }
        slideUp(document.querySelector(".nav"), 300);
        document.querySelector("body").classList.remove("menu-open");
    }
    e.preventDefault();
    e.stopPropagation();

    if (loginBtn.classList.contains("open")) {
        loginList.style.display = "none";
        loginBtn.classList.remove("open");
    }
})



loginBtn.addEventListener("click", function() {
    if (this.classList.contains("open")) {
        loginList.style.display = "none";
        this.classList.remove("open");
    } else {
        slideDown(loginList, 300);
        loginList.style.display = "block";
        this.classList.add("open");
    }

    if (document.querySelector(".nav-toggle").classList.contains("open")) {
        if (document.querySelector(".nav ul.open")) {
            slideUp(document.querySelector(".nav ul.open"), 300);
        }
        slideUp(document.querySelector(".nav"), 300);
        document.querySelector("body").classList.remove("menu-open");
        document.querySelector(".nav-toggle").classList.remove("open")
    }

});

// previousElementSibling
if (window.ontouchstart !== undefined) {
    document.querySelector("body").classList.add("menu-mobile");

    for (var i = 0; i < navItemToOpenA.length; i++) {
        navItemToOpenLi[i].addEventListener("click", function(e) {

            // e.preventDefault();
            // e.stopPropagation();
            let submenu = this.querySelector('.nav__level-2');

            if (submenu.classList.contains("m-open")) {
                slideUp(submenu, 300);
                submenu.classList.remove("m-open");
            } else {
                if (document.querySelector(".nav ul.m-open")) {
                    slideUp(document.querySelector(".nav ul.m-open"), 300);
                    document.querySelector(".nav ul.m-open").classList.remove("open");
                }
                slideDown(submenu, 300);
                submenu.classList.add("m-open");
            }
            if (document.querySelector(".nav ul.m2-open")) {
                slideUp(document.querySelector(".nav ul.m2-open"), 300);
                document.querySelector(".nav ul.m2-open").classList.remove("m2-open");
            }

        });
    }
    for (var i = 0; i < navItemToOpenA2.length; i++) {
        navItemToOpenLi2[i].addEventListener("click", function(e) {
            e.stopPropagation();

            let submenu = this.parentNode.querySelector(".nav__level-3");

            if (submenu.classList.contains("m2-open")) {
                slideUp(submenu, 300);
                submenu.classList.remove("m2-open");
            } else {
                if (document.querySelector(".nav ul.m2-open")) {
                    slideUp(document.querySelector(".nav ul.m2-open"), 300);
                    document.querySelector(".nav ul.m2-open").classList.remove("m2-open");
                }
                slideDown(submenu, 300);
                submenu.classList.add("m2-open");
            }
        });
    }


    // $(".team__item").click(function(e) {
    //     if ($(this).hasClass("open")) {
    //         $(this).removeClass("open");
    //     } else {
    //         $(".team__item.open").removeClass("open");
    //         $(this).addClass("open");
    //     }
    // })

} else {
    for (var i = 0; i < navItemToOpenA.length; i++) {
        navItemToOpenA[i].addEventListener("click", function(e) {
            // e.preventDefault();
            // e.stopPropagation();
        });
        navItemToOpenA2[i].addEventListener("click", function(e) {
            // e.preventDefault();
            // e.stopPropagation();
        });
    }
    for (var i = 0; i < navItemToOpenLi.length; i++) {
        navItemToOpenLi[i].addEventListener("mouseenter", function(e) {
            document.body.classList.add("menu-d-open");


            this.querySelector('.nav__level-2').style.display = "block";

            this.querySelector('.nav__level-2>li:first-child>a').classList.add('active');

            if (this.querySelector('.nav__level-2>li:first-child .nav__level-3')) { this.querySelector('.nav__level-2>li:first-child .nav__level-3').classList.add("open"); }

            if (this.getAttribute("data-img")) {
                this.querySelector('.nav__img img').src = this.getAttribute("data-img");
            }

        });
    }
    for (var i = 0; i < navItemToOpenLi.length; i++) {
        navItemToOpenLi[i].addEventListener("mouseleave", function(e) {
            this.querySelector('.nav__level-2').style.display = "none";
            this.classList.remove('active');

            document.body.classList.remove("menu-d-open");
        });
    }
    for (var i = 0; i < navItemToOpenLi2.length; i++) {
        navItemToOpenLi2[i].addEventListener("mouseenter", function(e) {
            if (document.querySelector('.nav__level-3')) { document.querySelector('.nav__level-3').classList.remove("open"); }
            if (document.querySelector('.nav a.active')) {
                document.querySelector('.nav a.active').classList.remove("active");
            }
            if (this.querySelector('.nav__level-3')) {
                this.querySelector('.nav__level-3').classList.add("open");
            }

            if (this.getAttribute("data-img")) {

                this.closest(".menu__item--has-children").querySelector(".nav__img img").src = this.getAttribute("data-img");
            }

        });
    }
    for (var i = 0; i < navItemToOpenLi2.length; i++) {
        navItemToOpenLi2[i].addEventListener("mouseleave", function(e) {
            if (this.querySelector('.nav__level-3')) {
                this.querySelector('.nav__level-3').classList.remove("open");
            }
            this.classList.add('remove');
        });
    }



    loginWrapper.addEventListener("mouseenter", function() {
        if (!loginBtn.classList.contains("open")) {
            loginList.style.display = "block";
            loginBtn.classList.add("open");
        }

    });
    loginWrapper.addEventListener("mouseleave", function() {
        if (loginBtn.classList.contains("open")) {
            loginList.style.display = "none";
            loginBtn.classList.remove("open");
        }

    });
    // for (var i = 0; i < teamItemList.length; i++) {
    //     teamItemList[i].addEventListener("mouseenter", function(e) {
    //         this.classList.add("open");
    //     });
    // }
    // for (var i = 0; i < teamItemList.length; i++) {
    //     teamItemList[i].addEventListener("mouseout", function(e) {
    //         this.classList.remove("open");
    //     });
    // }       
}


function accordion(accordion) {
    let accordionContent = accordion.querySelector(".accordion__content");
    let accordionBtn = accordion.querySelector(".accordion__btn");
    // let height = accordionContent.offsetHeight;

    // accordionContent.setAttribute("data-height", height + "px");
    // accordionContent.classList.contains("active") ? accordionContent.style.height = height + "px" : accordionContent.style.height = 0;
    accordionBtn.addEventListener("click", function() {
        if (accordion.classList.contains("active")) {
            this.classList.remove("active");
            this.setAttribute('aria-expanded', false);
            accordionContent.classList.remove("active");
            accordionContent.setAttribute('aria-hidden', true);
            slideUp(accordionContent);
            accordion.classList.remove("active");
        } else {
            this.classList.add("active");
            this.setAttribute('aria-expanded', true);
            accordion.classList.add("active");
            accordionContent.setAttribute('aria-hidden', false);
            accordionContent.classList.add("active");
            slideDown(accordionContent);
        }
    })
}

Array.prototype.map.call(document.querySelectorAll(".accordion__panel"), function(item) { accordion(item) });



//print

function printPDF(printBtn) {
    let print = () => {
        let pdfid = printBtn.getAttribute("data-print");
        let objFra = document.getElementById(pdfid);
        objFra.contentWindow.focus();
        objFra.contentWindow.print();
    }
    printBtn.addEventListener("click", function() {
        print();

    })
}
Array.prototype.map.call(document.querySelectorAll(".btn--print"), function(item) { printPDF(item) });



//swiper slder

//TAB
function tabsRun(wrapper) {

    var tabs = wrapper.querySelectorAll('[role=tab]'); //get all role=tab elements as a variable
    for (i = 0; i < tabs.length; i++) {
        tabs[i].addEventListener("click", showTabPanel);
    } //add click event to each tab to run the showTabPanel function 
    function showTabPanel(el) { //runs when tab is clicked
        var tabs2 = wrapper.querySelectorAll('[role=tab]'); //get tabs again as a different variable 
        for (i = 0; i < tabs2.length; i++) {
            tabs2[i].setAttribute('aria-selected', 'false');

        } //reset all tabs to aria-selected=false and normal font weight
        el.target.setAttribute('aria-selected', 'true'); //set aria-selected=true for clicked tab       
        var tabPanelToOpen = el.target.getAttribute('aria-controls');
        if (!tabPanelToOpen) {

            tabPanelToOpen = el.target.close("button").getAttribute('aria-controls');
        } //get the aria-controls value of the tab that was clicked
        var tabPanels = wrapper.querySelectorAll('[role=tabpanel]'); //get all tabpanels as a variable
        for (i = 0; i < tabPanels.length; i++) {
            tabPanels[i].setAttribute("aria-hidden", "true");
            tabPanels[i].classList.remove("is-current");

        } //hide all tabpanels

        wrapper.querySelector("#" + tabPanelToOpen).setAttribute("aria-hidden", "false");
        wrapper.querySelector("#" + tabPanelToOpen).classList.add("is-current"); //show tabpanel who's tab was clicked
    }
}




Array.prototype.map.call(document.querySelectorAll(".tabs"), function(item) { tabsRun(item) });


// $('[role=tablist]').keydown(function(e) {
//     if (e.keyCode == 37) {
//         $("[aria-selected=true]").prev().click().focus();
//         e.preventDefault();
//     }
//     if (e.keyCode == 38) {
//         $("[aria-selected=true]").prev().click().focus();
//         e.preventDefault();
//     }
//     if (e.keyCode == 39) {
//         $("[aria-selected=true]").next().click().focus();
//         e.preventDefault();
//     }
//     if (e.keyCode == 40) {
//         $("[aria-selected=true]").next().click().focus();
//         e.preventDefault();
//     }
// });
let touchstartX = 0;
let touchendX = 0;

if (document.querySelector('.elements__right')) {
    const gestureZone = document.querySelector('.elements__right');


    gestureZone.addEventListener('touchstart', function(event) {
        touchstartX = event.changedTouches[0].screenX;
    }, false);

    gestureZone.addEventListener('touchend', function(event) {
        touchendX = event.changedTouches[0].screenX;
        handleGesture();
    }, false);
}

function handleGesture() {
    if (touchendX >= touchstartX) {
        if (document.querySelector(".elements [aria-selected=true]").parentNode.previousElementSibling) {
            document.querySelector(".elements [aria-selected=true]").parentNode.previousElementSibling.querySelector("button").click();
        }
    }
    if (touchendX <= touchstartX) {
        if (document.querySelector(".elements [aria-selected=true]").parentNode.nextElementSibling) { document.querySelector(".elements [aria-selected=true]").parentNode.nextElementSibling.querySelector("button").click() }
    }

};


(function() {
    /* Opening modal window function */
    function openModal() {
        /* Get trigger element */
        var modalTrigger = document.getElementsByClassName('jsModalTrigger');

        /* Set onclick event handler for all trigger elements */
        for (var i = 0; i < modalTrigger.length; i++) {

            modalTrigger[i].onclick = function(e) {
                e.preventDefault();
                var target = this.getAttribute('data-href');
                var modalWindow = document.getElementById(target);

                modalWindow.classList ? modalWindow.classList.add('open') : modalWindow.className += ' ' + 'open';
            }
        }
    }

    function closeModal() {
        /* Get close button */
        var closeButton = document.getElementsByClassName('jsModalClose');
        var closeOverlay = document.getElementsByClassName('jsOverlay');

        /* Set onclick event handler for close buttons */
        for (var i = 0; i < closeButton.length; i++) {
            closeButton[i].onclick = function() {
                var modalWindow = this.parentNode.parentNode;

                modalWindow.classList ? modalWindow.classList.remove('open') : modalWindow.className = modalWindow.className.replace(new RegExp('(^|\\b)' + 'open'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
            }
        }

        /* Set onclick event handler for modal overlay */
        for (var i = 0; i < closeOverlay.length; i++) {
            closeOverlay[i].onclick = function() {
                var modalWindow = this.parentNode;

                modalWindow.classList ? modalWindow.classList.remove('open') : modalWindow.className = modalWindow.className.replace(new RegExp('(^|\\b)' + 'open'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
            }
        }

    }

    /* Handling domready event IE9+ */
    function ready(fn) {
        if (document.readyState != 'loading') {
            fn();
        } else {
            document.addEventListener('DOMContentLoaded', fn);
        }
    }

    /* Triggering modal window function after dom ready */
    ready(openModal);
    ready(closeModal);



}());





//kontakt-mapa



if (document.querySelector(".pm select")) {
    let contactSelect = document.querySelector(".pm select");
    let contactWoj = document.querySelector("#pm__woj");
    let contactList = document.querySelector("#pm__list");

    let mapItem = document.querySelectorAll(".map__item");
    let mapItemTxt = document.querySelectorAll(".map__txt");



    function removeNowActive() {

        if (document.querySelectorAll("#pm__list .active")) {
            let contactListLiOldActive = document.querySelectorAll("#pm__list .active");
            for (var i = 0; i < contactListLiOldActive.length; i++) {

                contactListLiOldActive[i].classList.remove("active");
            }
        }
    }

    contactSelect.addEventListener("change", function(e) {
        if (document.querySelector(".map__item.active")) { document.querySelector(".map__item.active").classList.remove("active"); }
        
        let newId = "";
        if(this.options[this.selectedIndex].getAttribute('data-value')){
            newId = this.options[this.selectedIndex].getAttribute('data-value');
        }

        removeNowActive()
        if (document.querySelectorAll("[data-id=" + newId + "]")) {
            let listActive = document.querySelectorAll("[data-id=" + newId + "]");
            for (var i = 0; i < listActive.length; i++) {
                listActive[i].classList.add("active");
            }
        }

        contactWoj.innerHTML=newId;
    })

    for (var i = 0; i < mapItem.length; i++) {
        mapItem[i].addEventListener("click", function() {
            if (document.querySelector(".map__item.active")) { document.querySelector(".map__item.active").classList.remove("active"); }
            this.classList.add("active");
            let newId = this.getAttribute("data-id");
            contactSelect.querySelector("[data-value=" + newId + "]").selected = true;
            removeNowActive();
            if (document.querySelectorAll("#pm__list [data-id=" + newId + "]")) {
                let listActive = document.querySelectorAll("[data-id=" + newId + "]");
                for (var i = 0; i < listActive.length; i++) {
                    listActive[i].classList.add("active");
                }
            }
            contactWoj.innerHTML=newId;

        })
    }
    for (var i = 0; i < mapItemTxt.length; i++) {
        mapItemTxt[i].addEventListener("click", function() {
            document.querySelector(".map__item.active").classList.remove("active");
            let newId = this.getAttribute("id");
            contactSelect.querySelector("[data-value=" + newId + "]").selected = true;

            document.querySelector(".map__item[data-id=" + newId + "]").classList.add("active");
            removeNowActive();
            if (document.querySelectorAll("#pm__list [data-id=" + newId + "]")) {
                let listActive = document.querySelectorAll("[data-id=" + newId + "]");
                for (var i = 0; i < listActive.length; i++) {
                    listActive[i].classList.add("active");
                }
            }
            contactWoj.innerHTML=newId;

        })

        mapItemTxt[i].addEventListener("mouseenter", function() {
            let newId = this.getAttribute("id");
            document.querySelector(".map__item[data-id=" + newId + "]").classList.add("hover");

        })
        mapItemTxt[i].addEventListener("mouseleave", function() {
            let newId = this.getAttribute("id");
            document.querySelector(".map__item[data-id=" + newId + "]").classList.remove("hover");

        })
    }



}

if (document.querySelector(".functionality__btnmobile")) {
    const btnOen = document.querySelector(".functionality__btnmobile");
    btnOen.addEventListener("click", function() {
        slideToggle(document.querySelector(".functionality__left"));
        this.classList.toggle("open");
    })

    let btns = document.querySelectorAll(".functionality__left .tabs__nav-trigger");
    for (var i = 0; i < btns.length; i++) {
        btns[i].addEventListener("click", function() {
            btnOen.innerHTML = this.querySelector("strong").innerHTML;
            if (btnOen.classList.contains("open")) {
                slideUp(document.querySelector(".functionality__left"));
                btnOen.classList.remove("open");
            }

        })
    }
}


var pointerX = -1;
var pointerY = -1;



if (document.querySelector(".hero-clipping-wrapper"))

{
    setTimeout(function() {

        if (window.innerWidth > 576) {
            document.querySelector(".hero-clipping-wrapper").style.width = "7%";
        } else {
            document.querySelector(".hero-clipping-wrapper").style.width = "10px";
        }


        document.querySelector("#hero-move").addEventListener("mousemove", function(event) {

            pointerX = event.pageX;
            pointerY = event.pageY;

            document.querySelector(".hero-clipping-wrapper").style.width = event.pageX + "px";
        });


        document.querySelector("#hero-move").addEventListener("touchmove", function(event) {

            pointerX = event.touches[0].clientX;
            pointerY = event.pageY;

            document.querySelector(".hero-clipping-wrapper").style.width = event.touches[0].clientX + "px";
        });


    }, 3000)

}




if (document.querySelector(".functionality__left .tabs__nav-trigger")) {
    let imgToAnimate = document.querySelector(".functionality__img1");
    let btns = document.querySelectorAll(".functionality__left .tabs__nav-trigger");
    for (var i = 0; i < btns.length; i++) {
        btns[i].addEventListener("click", function() {


            if (!imgToAnimate.classList.contains("active")) {
                imgToAnimate.classList.add("active");
                setTimeout(function() { imgToAnimate.classList.remove("active") }, 700);
            }

            let btnId = this.getAttribute("aria-controls");
            let tabpane = document.querySelector("#" + btnId);

            if (tabpane.querySelector("video")) {
                tabpane.querySelector("video").play();
            }
        })
    }
}

if (document.querySelector(".functionality__left .accordion__btn")) {
    let btns = document.querySelectorAll(".functionality__left .accordion__btn");

    for (var i = 0; i < btns.length; i++) {

        btns[i].addEventListener("click", function() {

            if (window.innerWidth > 768) {

                if (this.classList.contains("active")) {
                    this.closest(".accordion__panel").querySelector(".tabs__nav-trigger").click();
                }
            }


        })
    }
}


//kopiowanie tekstu z inputa



function openFullscreen(elem) {
    if (elem.requestFullscreen) {
        elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
    }
}

function copyToClipboard(copyBox) {
    let copyInput = copyBox.querySelector(".to-copy");
    let copyBtn = copyBox.querySelector("button");
    // let height = accordionContent.offsetHeight;

    // accordionContent.setAttribute("data-height", height + "px");
    // accordionContent.classList.contains("active") ? accordionContent.style.height = height + "px" : accordionContent.style.height = 0;
    copyBtn.addEventListener("click", function() {
        navigator.clipboard.writeText(copyInput.innerHTML);
    })


}

Array.prototype.map.call(document.querySelectorAll(".wl__item--copy"), function(item) { copyToClipboard(item) });



function playVideo(videoBox) {
    let video = videoBox.querySelector("video");
    let playBtn = videoBox.querySelector(".btn--play");

    let otherVideos = document.querySelectorAll(".card__item--play video")
    // let height = accordionContent.offsetHeight;

    // accordionContent.setAttribute("data-height", height + "px");
    // accordionContent.classList.contains("active") ? accordionContent.style.height = height + "px" : accordionContent.style.height = 0;
    playBtn.addEventListener("click", function() {
        if (video) {
            video.play();
            openFullscreen(video);
            if (playBtn) { playBtn.style.display = "none"; }
        }

    })

    if (video) {
        video.addEventListener('pause', (event) => {
            playBtn.style.display = "block";
        });
        video.addEventListener('play', (event) => {
            playBtn.style.display = "none";

            for (var i = 0; i < otherVideos.length; i++) {
                if (video != otherVideos[i]) {

                    if (otherVideos[i].currentTime > 0) {
                        otherVideos[i].pause();
                        if (otherVideos[i].querySelector(".btn--play")) { otherVideos[i].querySelector(".btn--play").style.display = "block"; }
                    }
                }
            }


        });
    }


}

Array.prototype.map.call(document.querySelectorAll(".card__item--play"), function(item) { playVideo(item) });




function sliderImgFade(wrapper) { 
    let bigPItems = wrapper.querySelectorAll(".img-slider-fade");   
    let bigPItemsLength = bigPItems.length;
    let bigPItemActive = 0;


    if (bigPItems.length > 1) {
        setInterval(function() {
            bigPItems[bigPItemActive].classList.remove("active");
          
            if (bigPItemActive == bigPItemsLength - 1) {
                bigPItemActive = 0;
            } else {
                bigPItemActive++;
            }
            bigPItems[bigPItemActive].classList.add("active");
            
        }, 10000)
    }
   


}

Array.prototype.map.call(document.querySelectorAll(".slider-fade"), function(item) { sliderImgFade(item) });


if(document.querySelector(".product1__rbtn--32")){
    let btn32=document.querySelector(".product1__rbtn--32");
    let btn35=document.querySelector(".product1__rbtn--35");
    let img32=document.querySelector(".product1__rightImg--32");
    let img35=document.querySelector(".product1__rightImg--35");

    btn32.addEventListener("click", function(e){
        e.preventDefault();
        e.stopPropagation();
        console.log("click");
        btn32.classList.remove("active");
        btn32.classList.add("hidden");
        btn35.classList.add("active");
        btn35.classList.remove("hidden");
        img35.classList.remove("active");
        img35.classList.add("hidden");
        img32.classList.add("active");
        img32.classList.remove("hidden");
    })
    btn35.addEventListener("click", function(e){
        e.preventDefault();
        e.stopPropagation();
        console.log("click2");
        btn35.classList.remove("active");
        btn35.classList.add("hidden");
        btn32.classList.add("active");
        btn32.classList.remove("hidden");
        img32.classList.remove("active");
        img32.classList.add("hidden");
        img35.classList.add("active");
        img35.classList.remove("hidden");
    })
}